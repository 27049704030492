import api from "../httpClient.js";

export async function getTableViews(filtros) {
  const response = await api.get(`/table-views${filtros ? filtros : ""}`);
  return response.data.tabelas;
}

export async function getTableView(filtros) {
  const response = await api.get(`/table-view${filtros ? filtros : ""}`);
  return response.data.tabela;
}

export function notificar(item) {
  return api.post("/notificar-cliente", item);
}
