<template>
  <v-container v-if="vueNotificarClientes">
    <PageToolbar title="Notificar clientes" icon="sms" dark />

    <v-card class="mt-6">
      <v-card-text class="px-4 py-1 lightgray">
        <v-row>
          <v-col cols="12" md="9">
            <v-select
              v-model="tabela"
              :items="tabelas"
              :loading="loadingTabelas"
              :disabled="tabelas.length ? false : true"
              label="Selecionar Lista"
              @change="getMessage"
              clearable
              @clear="mensagem = ''"
              hide-details
              dense
              outlined
              solo
              flat
            ></v-select>
          </v-col>

          <v-col>
            <v-btn
              :disabled="tabela ? false : true"
              :loading="loadingTabelaResult"
              depressed
              block
              color="buttons"
              height="40px"
              class="white--text"
              @click="getTable"
            >
              Buscar
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-expansion-panels flat>
              <v-expansion-panel>
                <v-expansion-panel-header style="min-height: 40px" class="py-0">
                  <div>
                    <v-icon left>sms</v-icon>
                    Editar Mensagem
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-divider></v-divider>
                  <v-card flat class="pa-4">
                    <v-textarea
                      v-model="mensagem"
                      label="Mensagem"
                      auto-grow
                      :rows="3"
                      dense
                      outlined
                      hide-details
                    ></v-textarea>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>

        <v-row>
          <!-- Empresa -->
          <v-col cols="12" md="9" class="d-flex">
            <v-icon color="toolbar" class="mr-2">local_cafe</v-icon>
            <v-progress-linear
              :value="valueDeterminate"
              :background-opacity="0.5"
              color="toolbar"
              height="40px"
              rounded
              class="white--text"
            >
              <template v-slot="{}">
                <strong> {{ tabela_result.length }} clientes restantes </strong>
              </template>
            </v-progress-linear>
          </v-col>

          <!-- Gerar Lista -->
          <v-col>
            <v-btn
              v-if="!enviandoMensagens"
              @click="enviarMensagens"
              :disabled="tabela_result.length ? false : true"
              block
              color="buttons"
              class="white--text"
              height="40px"
              depressed
            >
              <v-icon left>play_arrow</v-icon>
              Enviar Mensagens
            </v-btn>
            <v-btn
              v-if="enviandoMensagens"
              @click="pararEnvio = true"
              block
              color="buttons"
              class="white--text"
              height="40px"
              depressed
              style="max-width: 100%;"
            >
              <v-icon left>pause</v-icon>
              Pausar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          v-if="!loadingTabelaResult"
          :headers="headers"
          :items="tabela_result"
          item-key="index"
          :items-per-page="15"
          :item-class="row_classes"
          class="data-tables"
        >
        </v-data-table>
        <Carregando v-else />
      </v-card-text>
    </v-card>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import { all as fetchMessage } from "@/services/api/tocsconfig.api.js";
import {
  getTableViews,
  getTableView,
  notificar,
} from "@/services/api/notificar-clientes.api.js";

import { mapGetters } from "vuex";

export default {
  name: "NotificarClientes",

  data() {
    return {
      loadingTabelas: false,
      tabelas: [],
      tabela: "",
      loadingTabelaResult: false,
      tabela_result: [],
      enviandoMensagens: false,
      pararEnvio: false,
      valueDeterminate: 0,
      loadingMensagem: false,
      mensagem: "",
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueNotificarClientes() {
      return this.getAccess("vueNotificarClientes", "menu");
    },
    headers() {
      return [
        {
          text: "nome",
          value: "nome",
        },
        {
          text: "celular",
          value: "celular",
        },
        {
          text: "telefone",
          value: "telefone",
        },
        {
          text: "cidade",
          value: "cidade",
        },
      ];
    },
  },

  methods: {
    row_classes(item) {
      if (item.gravando) {
        //can also return multiple classes e.g ["orange","disabled"]
        return ["error--text", "font-weight-bold"];
      }
    },
    getTables() {
      this.loadingTabelas = true;
      getTableViews()
        .then((response) => {
          this.tabelas = response;
          this.loadingTabelas = false;
        })
        .catch((error) => {
          this.loadingTabelas = false;
        });
    },
    getTable() {
      this.loadingTabelaResult = true;
      getTableView(`?tabela=${this.tabela}`)
        .then((response) => {
          response.forEach((item) => {
            if (item.celular) {
              item.celular = item.celular.match(/\d+/g).join([]);
            }
            if (item.telefone) {
              item.telefone = item.telefone.match(/\d+/g).join([]);
            }
          });
          this.tabela_result = response;
          this.loadingTabelaResult = false;
        })
        .catch((error) => {
          this.loadingTabelaResult = false;
        });
    },
    getMessage() {
      this.loadingMensagem = true;
      fetchMessage(`?campo=${"whatsapp_mensagem_" + this.tabela}`)
        .then((response) => {
          this.mensagem = response[0].valor;
          this.loadingMensagem = false;
        })
        .catch((error) => {
          this.loadingMensagem = false;
        });
    },
    async enviarMensagens() {
      let total = this.tabela_result.length - 1;
      if (this.tabela_result.length) {
        this.enviandoMensagens = true;
        for (let i = 0; i <= total; i++) {
          if (!this.pararEnvio) {
            this.tabela_result[0].gravando = true;
            this.tabela_result[0].mensagem = this.mensagem.replace(
              "[CLIENTE]",
              this.tabela_result[0].nome
            );
            this.$Progress.start();
            const response = await notificar(this.tabela_result[0]);
            if (response.status === 200) {
              this.valueDeterminate = (i / total) * 100;
              this.$Progress.finish();
              this.tabela_result.splice(0, 1);
            }
          } else {
            i = total;
          }
        }
        if (this.pararEnvio) {
          this.$toast.success("Envio de mensagens pausada");
          this.valueDeterminate = 0;
        } else {
          this.$toast.success("Envio de mensagens finalizada");
        }
        this.enviandoMensagens = false;
        this.pararEnvio = false;
      }
    },
  },

  mounted() {
    this.getTables();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-expansion-panel-content__wrap {
  padding: 0px;
}

.theme--light ::v-deep .v-expansion-panel:before {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.12) !important;
}

.theme--dark ::v-deep .v-expansion-panel:before {
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.13) !important;
}
</style>
